*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
html {
  /* height: 100%; */
}
body {
  font-family: poppins, sans-serif;
  height: 100vh;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --jfh-color-brown: #423737;
  --jfh-color-darkBrown: #312a2a;
  --jfh-color-yellow: #f4eab1;
  --jfh-color-softWhite: #faf9f6;
  --jfh-color-whiteHeader: rgba(250, 249, 246, 0.95);
  --jfh-color-whiteHeader-alt: rgba(250, 249, 246, 0.85);
  --jfh-color-whiteBg: rgba(250, 249, 246, 0.65);
  background-image: url("/src/assets/backgrounds/background-pattern.jpg");
  background-color: var(--jfh-color-brown);
  background-repeat: repeat;
}
.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  line-height: 0.5em;
}
p {
  margin-top: 1em;
  line-height: 1.2em;
}
a {
  font-weight: 600;
  color: var(--jfh-color-darkBrown);
  text-decoration: none;
}
a:hover {
  color: var(--jfh-color-brown);
}
.site-container {
  display: flex;
  flex-direction: column;
  /* align-items: stretch; */
  min-height: 100vh;
  /* height: 100%; */
}
.site-header-background {
  background-image: url("/src/assets/jfhStripesBg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 10em;
  margin: 0 auto;
}
.sold-out {
  color: #ff0000;
}
.site-title-background {
  width: clamp(18rem, 5.727rem + 61.364vw, 45rem);
  min-height: 6em;
  background: var(--jfh-color-softWhite);
  padding: 2em 1em;
  outline: 1px solid var(--jfh-color-brown);
  outline-offset: -0.5em;
  cursor: pointer;
}
.site-header-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--jfh-color-darkBrown);
  align-items: center;
}
.site-header-hero-text {
  font-size: clamp(1.9rem, 1rem + 2vw, 4rem);
  font-weight: 200;
}
.site-header-divider {
  border-bottom: 1px solid var(--jfh-color-darkBrown);
  width: 60%;
  margin: 0.3em;
}
.site-header-sub-text {
  font-size: clamp(0.85rem, -0.091rem + 1.705vw, 1rem);
  text-align: center;
}

.site-details-socials {
  padding-top: 0.25em;
  display: flex;
  align-items: center;
}
.site-details-title {
  flex: 1;
}
.site-mint-details-container {
  display: flex;
  gap: 1em;
  font-size: 0.9em;
  color: var(--jfh-color-darkBrown);
}
.site-mint-details-pair {
  display: flex;
  align-items: center;
  padding: 0.4em 0.5em;
  border-radius: 0.3em;
  backdrop-filter: blur(3px);
  background-blend-mode: screen;
  background-color: rgba(255, 255, 255, 0.35);
}
.site-mint-detail {
  font-size: 0.9em;
}
.pair-solid {
  background-color: rgba(255, 255, 255, 0.85);
}
.site-mint-details-title {
  font-weight: 500;
  margin-right: 0.25em;
}

.site-footer {
  font-size: 0.75rem;
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  letter-spacing: 0.05em;
  color: var(--jfh-color-softWhite);
  background-color: var(--jfh-color-darkBrown);
}
button.xmint-btn {
  font-family: poppins, sans-serif;
  letter-spacing: 0.02em;
  background-color: var(--jfh-color-brown);
  padding: 0.1em 0.6em;
  font-weight: 200;
  margin-left: 1em;
  border-radius: 0.25rem;
  min-width: 11.5em;
}
.crossmintImg-0-2-2 {
  max-height: 1.2em;
  margin: 0.15rem 0rem !important;
}
button.xmint-btn span {
  display: none;
}
button.xmint-btn:after {
  content: "Use Credit Card";
  margin-top: 0.1em;
  color: white;
}
.navbar-background {
  width: 100%;
  background-color: var(--jfh-color-darkBrown);
}
.navbar-container {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  padding: 0.25rem 0;
}
.navbar-page-buttons {
  flex: 1;
}
.btn-group {
  display: flex;
  gap: 0.5rem;
  z-index: 1000;
}

.btn-bar {
  display: flex;
  align-items: center;
  height: 1.2rem;
  border-left: 0px solid var(--jfh-color-yellow);
  transition: all 200ms ease-in-out;
}
.btn-bar:hover {
  border-radius: 2px;
  border-left: 3px solid var(--jfh-color-yellow);
}

.btn-text {
  color: var(--jfh-color-softWhite);
  cursor: pointer;
  margin-left: 0.5rem;
  font-size: 0.9rem;
  font-weight: 200;
  transition: all 200ms ease-in-out;
}
.btn-text:hover {
  color: var(--jfh-color-yellow);
  letter-spacing: 0.05rem;
}

.hamburger-bar {
  display: block;
  width: 25px;
  height: 2px;
  margin: 6px 0;
  transition: all 0.3s ease-in-out;
  background-color: var(--jfh-color-yellow);
}
.hamburger {
  display: none;
  transition: 0.3s;
}
.mint-status-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: var(--jfh-color-yellow);
  /* font-size: 0.8rem; */
  gap: 0.05em;
}
.mint-status-title {
  color: var(--jfh-color-softWhite);
  font-size: 0.65rem;
  font-weight: 200;
  letter-spacing: 0.12em;
}
.mint-status-data {
  font-size: 0.8rem;
  /* font-weight: 200; */
  letter-spacing: 0.12em;
}
.mint-status-container {
  padding-top: 0.1em;
  width: auto;
}
.mint-status {
  display: flex;
  justify-content: flex-end;
  padding: 0.25em 0;
  width: 80%;
  margin: auto;
  color: var(--jfh-color-softWhite);
}
.smaller-font {
  font-size: 0.85em;
}
.social-group-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 0.25em;
  padding: 0.01em;
  width: clamp(5rem, -18.324rem + 116.618vw, 9rem);
}
.social-link {
  color: var(--jfh-color-darkBrown);
  fill: var(--jfh-color-darkBrown);
  transition: all 300ms ease-in-out;
  cursor: pointer;
}
.social-link:hover {
  color: var(--jfh-color-softWhite);
  fill: var(--jfh-color-softWhite);
  transform: scale(115%);
}
.social-link-rev {
  cursor: pointer;
  color: var(--jfh-color-softWhite);
  fill: var(--jfh-color-softWhite);
  transition: all 300ms ease-in-out;
}
.social-link-rev:hover {
  color: var(--jfh-color-yellow);
  fill: var(--jfh-color-yellow);
  transition: all 300ms ease-in-out;
  transform: scale(115%);
}
.page-container {
  width: 80%;
  margin: auto;
  color: var(--jfh-color-darkBrown);
}
.page-background {
  background-image: url("/src/assets/jfhPageBodyBg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  border-bottom: 0.35rem solid var(--jfh-color-darkBrown);
  border-top: 0.35rem solid var(--jfh-color-darkBrown);
  /* padding-bottom: 1em; */
}
.page-background-solid {
  background-color: var(--jfh-color-darkBrown);
  color: var(--jfh-color-softWhite);
  border-bottom: 0.35rem solid var(--jfh-color-brown);
  border-top: 0.35rem solid var(--jfh-color-brown);
}
.page-title {
  color: var(--jfh-color-darkBrown);
  font-size: 2em;
  padding: 1em 0;
  font-weight: 600;
}
.page-title-reverse {
  color: var(--jfh-color-softWhite);
}
.page-card-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  color: var(--jfh-color-darkBrown);
  backdrop-filter: blur(3px);
  background-blend-mode: screen;
  /* margin: auto; */
}
/* Custom card position for Home Page, under Johnny Image */
.home-page-card {
  margin-top: clamp(-2rem, 3.357rem + -14.286vw, 0.5rem);
  max-width: 55em;
}
.page-card-header {
  font-weight: 600;
  font-size: 1.5em;
  line-height: 1.2em;
  padding: 1em 1em 0.65em 1em;
  border-top-left-radius: 0.65rem;
  background-color: rgba(244, 234, 177, 0.7);
  box-shadow: 0 0.65em 0.5rem -0.25rem rgba(49, 42, 42, 0.25);
}
.page-card-content {
  padding: 0em 1em 1em 1em;
  border-bottom-right-radius: 0.65rem;
  background-color: rgba(244, 234, 177, 0.4);
}
.card-slider {
  padding-top: 1em;
}
.card-header-white {
  background-color: var(--jfh-color-whiteHeader);
}
.card-content-white {
  background-color: var(--jfh-color-whiteBg);
}
.header-alt {
  background-color: var(--jfh-color-whiteHeader-alt);
}
.card-content-header {
  margin-top: 2em;
  font-size: 1.35em;
  font-weight: 600;
}
.page-button {
  cursor: pointer;
  background-color: var(--jfh-color-brown);
  border: none;
  font-family: poppins, sans-serif;
  color: var(--jfh-color-softWhite);
  font-size: clamp(0.5rem, 0.798rem + 0.511vw, 1.125rem);
  font-weight: 400;
  letter-spacing: 0.05em;
  padding: 1em;
  border-radius: 0.5rem;
  box-shadow: 0 0 1em var(--jfh-color-darkBrown) inset;
}
.button-alt {
  padding: 0.5em 1em;
}
.btn-div {
  display: flex;
  align-items: center;
  gap: 0.2em;
}
.page-button:hover {
  color: var(--jfh-color-yellow);
  backdrop-filter: blur(3px);
  background-blend-mode: multiply;
  background-color: rgba(49, 42, 42, 0.9);
}
.page-button:disabled{
  opacity: 50%;
}
.home-page-motion-group {
  display: flex;
  justify-content: center;
  color: var(--jfh-color-darkBrown);
  font-size: clamp(1.25rem, 0.909rem + 1.705vw, 2rem);
  font-weight: 800;
}
.home-page-johnny-image {
  width: clamp(16rem, 9.571rem + 32.143vw, 25rem);
  z-index: 500;
  opacity: 0%;
  -webkit-animation-name: home-page-image;
  -webkit-animation-delay: 2.8s;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease-in;
  -webkit-animation-fill-mode: forwards;
  animation-name: home-page-image;
  animation-delay: 2.8s;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  transition: all 500ms;
}
.home-page-text-1 {
  opacity: 0;
  margin-top: 2em;
  position: relative;
  animation-delay: 1s;
  animation-name: home-page-text-1;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.home-page-text-2 {
  opacity: 0;
  position: relative;
  animation-delay: 2.2s;
  animation-name: home-page-text-2;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.legendary-page-slider-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.tale-page-nft-image {
  width: clamp(14rem, 11.273rem + 13.636vw, 20rem);
  border-radius: 0.5rem;
}
.tale-page-generated-combo {
  display: flex;
  align-items: center;
  background-image: url("/src/assets/backgrounds/paper-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  /* background-color: rgb(0, 0, 0); */
}
.tale-page-medium-text {
  color: #554e4e;
  font-size: 0.9em;
}
.tale-page-story-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  padding: 1.2em;
  line-height: 2em;
  background-color: #f4eab19f;
  border-radius: 1rem;
}
.tale-page-trait-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  color: var(--jfh-color-softWhite);
  margin-top: 1em;
  margin-bottom: 2em;
}
.tale-page-trait-group {
  width: clamp(13rem, 9.364rem + 18.182vw, 21rem);
  display: flex;
  flex-direction: column;
  background-color: #312a2a0e;
  padding: 0 1em;
  border-left: 0.3em solid var(--jfh-color-yellow);
  border-top-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background-blend-mode: multiply;
}
.tale-page-trait-title {
  padding-top: 0.5em;
  font-size: 1.2em;
  font-weight: 400;
  letter-spacing: 0.025em;
}
.tale-page-trait-image {
  width: clamp(10rem, 6.364rem + 18.182vw, 18rem);
}
.tale-page-trait-text {
  margin-top: -0.5em;
  font-size: 0.8em;
  font-weight: 200;
  line-height: 1em;
  margin-bottom: 0.5em;
}
.trait-page-card-container {
  display: inline-flex;
  flex-flow: column wrap;
  gap: 0.7rem;
  color: #312a2a;
  margin-bottom: 1.5em;
  /* 
  // Use: https://www.marcbacon.com/tools/clamp-calculator/
  // reverse min/max with online tool
  // swap min/max in final CSS to be correct (leaving middle calc, as-is)
  */
  max-height: clamp(150rem, 950rem + -500vw,710rem );
  width: 100%;
}
.trait-page-card {
  border-top-left-radius: 0.5em;
  background-color: #312a2aad;
  backdrop-filter: blur(0.1em);
}
.trait-page-card-title {
  border-top-left-radius: 0.5em;
  font-weight: 600;
  background-color: var(--jfh-color-whiteHeader);
  padding: 0.3em 0.6em;
}
.trait-page-card-header {
  padding: 0.1em 0;
  font-size: 1.125em;
  font-weight: 600;
}
.trait-page-card-data {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5em;
  background-color: var(--jfh-color-whiteBg);
  padding-left: 0.6em;
  border-bottom: 1px solid var(--jfh-color-darkBrown);
}
.trait-page-card-data:last-child {
  border-bottom-right-radius: 0.5em;
  box-shadow: 0 1em 0.5em rgba(0, 0, 0, 0.145);
  border-bottom: none;
}
.trait-page-card-data:nth-child(odd) {
  background-color: rgba(250, 249, 246, 0.5);
}
.trait-card-data-row {
  font-size: 0.8em;
  line-height: 14px;
  padding: 2px;
}
.trait-card-data-row:first-child {
  flex: 1;
}
.trait-card-data-row:last-child,
.trait-card-data-row:nth-child(2) {
  min-width: 3em;
}

.more-info-page-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1em;
  /* 
  // Use: https://www.marcbacon.com/tools/clamp-calculator/
  // reverse min/max with online tool
  // swap min/max in final CSS to be correct (leaving middle calc, as-is)
  */
  /* max-height: clamp(53rem, 95.092rem + -46.769vw, 72rem); */
  max-height: clamp(60rem, 99rem + -50vw,75rem );
  align-items: center;
}
.more-info-page-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 0.65rem;
  border-bottom-right-radius: 0.65rem;
  background-blend-mode: multiply;
  width: calc(50% - 1em);
}
.bg-01 {
  background-image: url("/src/assets/backgrounds/skulloftyger_OG_option1.jpg");
}
.bg-02 {
  background-image: url("/src/assets/backgrounds/JFH_OG_bg.jpg");
}
.bg-03 {
  background-image: url("/src/assets/backgrounds/skullOfTyger_presaleBG.jpg");
}
.bg-04 {
  background-image: url("/src/assets/backgrounds/lgs_diamond_bg.jpg");
}
.bg-05 {
  background-image: url("/src/assets/backgrounds/skullOfTygerBG.jpg");
}
.bg-06 {
  background-image: url("/src/assets/backgrounds/healingWallet_BG.jpg");
}
.section-tab {
  display: flex;
  align-items: center;
  color: var(--jfh-color-softWhite);
  background-color: var(--jfh-color-brown);
  border-bottom-left-radius: 0.65em;
  border-bottom-right-radius: 0.65em;
  width: clamp(14rem, 1.143rem + 64.286vw, 32rem);
  padding: clamp(0.5rem, -0.214rem + 3.571vw, 1.5rem);
  line-height: 1.25em;
  margin-bottom: 1em;
  box-shadow: 0 1rem 0.5rem -1rem #0f0d0d;
  background-blend-mode: multiply;
}
.section-tab-header {
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.2em;
}
.section-tab a {
  color: var(--jfh-color-softWhite);
}
.section-tab a:hover {
  color: var(--jfh-color-yellow);
}
form > div {
  display: flex;
  flex-direction: column;
  margin: 1em;
}
form label {
  color: var(--jfh-color-softWhite);
}
label span {
  font-size: 0.8em;
}
form input {
  max-width: 34em;
  padding: 0.7em;
  color: var(--jfh-color-darkBrown);
  border: 3px solid var(--jfh-color-brown);
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  margin-top: 0.2em;
}
form input:focus {
  border: 3px solid var(--jfh-color-yellow);
}

input[type="submit"] {
  cursor: pointer;
  width: max-content;
  background-color: var(--jfh-color-darkBrown);
  border: none;
  font-family: poppins, sans-serif;
  color: var(--jfh-color-yellow);
  font-size: clamp(0.5rem, 0.798rem + 0.511vw, 1.125rem);
  font-weight: 400;
  letter-spacing: 0.05em;
  padding: 1em;
  border-radius: 0.5rem;
  transition: all 400ms;
  border: 3px solid var(--jfh-color-brown);
}
input[type="submit"]:hover {
  font-weight: 600;
  letter-spacing: 0.05em;
}
.section-full-width {
  margin-top: 1em;
  display: block;
  width: 100%;
}
.section-tab-callout {
  font-size: 2em;
  align-self: baseline;
  padding-top: 0.1em;
}

.add-shadow {
  box-shadow: 0 0 2rem #312a2a inset;
  background-color: #312a2abb;
  width: clamp(14rem, 6.143rem + 39.286vw, 25rem);
  font-size: clamp(0.7rem, 0.486rem + 1.071vw, 1rem);
  /* width: clamp(14rem, 1.143rem + 64.286vw, 32rem); */
  /* background-blend-mode: multiply; */
}
.minted-generated-combo {
  display: flex;
  align-items: flex-start;
  background-image: url("/src/assets/backgrounds/paper-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  /* background-color: rgb(0, 0, 0); */
}
.nft-image {
  padding: 1em;
  align-self: flex-start;
  width: clamp(16rem, -5.273rem + 86.364vw, 30rem);
  
}
.nft-badge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  margin-bottom: 1em;
}

.nft-badge-text {
  padding: 0.2rem 0.65rem;
  font-weight: 500;
  background-color: var(--jfh-color-whiteHeader);
  color: var(--jfh-color-darkBrown);
  border-radius: 0.65rem;
  font-size: 0.8em;
}

.nft-badge-header {
  flex: auto;
  font-size: 1.2em;
  font-weight: 600;
}
.nft-badge-img {
  max-width: 6rem;
}
.nft-badge-group{
  display: flex; 
  gap: .5rem;
  flex-wrap: wrap;
  border-left: 0.3rem solid var(--jfh-color-darkBrown);
  border-radius: 0.3rem;
  /* align-items: center; */
  /* justify-content: center; */
}
.mint-explorer-group {
  display: flex;
  align-items: center;
}

.mint-explorer-container {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.mint-explorer-icon {
  max-width: 1.5rem;
}
.mint-explorer-view {
  color: var(--jfh-color-darkBrown);
  background-color: var(--jfh-color-yellow);
  border-radius: 0.5em;
  padding: 0.5em;
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: 0.05em;
}
.mint-bottom {
  display: flex;
  /* width: max-content; */
  justify-content: center;
  margin-bottom: 0.5rem;
  /* background-color: var(--jfh-color-darkBrown); */
  padding: 1em;
}
.swiper-image {
  width: 340px;
  border-radius: 10px;
}
.swiper-image-large {
  width: 800px;
}
.swiper-thumb-image {
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  background-color: #f4eab1;
}
.swiper-pagination-bullet {
  opacity: 100%;
}
.slider-container {
  width: calc(50% - 0.5rem);
}
.slider-container-large {
  width: 100%;
}
.swiper-button-prev {
  background-image: url("./assets/icons/arrowPrev.png");
}
.swiper-button-next {
  background-image: url("./assets/icons/arrowNext.png");
}
.swiper-button-prev,
.swiper-button-next {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}
.swiper-zoom-container {
  cursor: pointer;
}
.countdown-container {
  display: flex;
  font-size: 0.8em;
  gap: 0.5em;
  flex: 1;
  /* text-align: right; */
  /* align-items: "center"; */
  /* min-width: 28em; */
  width: max-content;
}
.viewer-button-contain{
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-bottom: .5rem;
}

@keyframes home-page-image {
  from {
    opacity: 0%;
    -webkit-opacity: 0%;
  }
  to {
    opacity: 100%;
    -webkit-opacity: 100%;
  }
}
@keyframes home-page-text-1 {
  from {
    opacity: 0%;
    left: -500px;
    -webkit-opacity: 0%;
    -webkit-left: -500px;
  }
  to {
    opacity: 100%;
    left: 0px;
    -webkit-opacity: 100%;
    -webkit-left: 0px;
  }
}
@keyframes home-page-text-2 {
  from {
    opacity: 0%;
    left: -500px;
    -webkit-opacity: 0%;
    -webkit-left: -500px;
  }
  to {
    opacity: 100%;
    left: clamp(2rem, 0.068rem + 9.659vw, 6.25rem);
    -webkit-opacity: 100%;
    -webkit-left: 100px;
  }
}

@media (max-width: 1024px) {
  .minted-generated-combo{
    flex-direction: column;
   
  }
  .nft-image{
    align-self: center;
  }
}
@media (max-width:900px){
  .btn-group {
    display: none;
    flex-direction: column;
    position: fixed;
    margin-top: 2.4rem;
    background-color: var(--jfh-color-brown);
    padding: 1em;
    width: 15rem;
    border-radius: 0 0 18px 0;
    transition: 0.3s;
  }
  .btn-group.active {
    display: block;
  }
  .btn-bar {
    margin-top: 0.5rem;
  }
  .hamburger {
    display: block;
    cursor: pointer;
  }
  .hamburger.active .hamburger-bar:nth-last-child(2) {
    opacity: 0;
  }
  .hamburger.active .hamburger-bar:nth-last-child(3) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .hamburger-bar:nth-last-child(1) {
    transform: translateY(-8px) rotate(-45deg);
  }
}
@media (max-width: 768px) {

  .home-page-motion-group {
    flex-direction: column;
    align-items: center;
  }
  .legendary-page-slider-group {
    flex-direction: column;
  }
  .slider-container {
    width: 100%;
  }
  .tale-page-generated-combo {
    flex-direction: column;
  }

  .more-info-page-container {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    display: flex;
  
    max-height: max-content;
      }
  .more-info-page-background {
    width: 80%;
  }
  .mint-explorer-text {
    display: none;
  }
  .trait-page-card-container{
    max-height: max-content;
  }
}
@media (max-width: 500px) {
  .nft-badge-group{
  
    justify-content: center;
  }
  .countdown-container {
    display: block;
    text-align: right;
    /* width: min-content; */
  }
  .site-mint-details-container {
    flex-direction: column;
    width: 10rem;
    gap: 0.25em;
  }

}
